export const SOURCE_STRINGS = {
  Nespechej: 'Nespěchej.cz',
  NasDum: 'Nášdům.eu',
  PlneZdravi: 'Plnézdraví.cz',
  NaseZahrada: 'Našezahrada.com',
  EPenize: 'Epeníze.eu',
  SvetCestovatele: 'Světcestovatele.cz',
  Aktualne: 'Aktuálně.cz',
  Vareni: 'Vaření.cz',
  Woman: 'Žena.cz',
  Nova: 'TN.cz',
  Utulne: 'Útulně',
  Video: 'Aktuálně.cz',
  Magazin: 'Aktuálně.cz',
  MagazinZahrada: 'Magazín zahrada',
  AktualneAuto: 'Aktuálně.cz',
  VitalWeb: 'Vitalweb.cz',
  Kinotip: 'Kinotip',
  Zdrave: 'Zdravě',
  FajntipPsychologie: 'Fajntip',
  Ekonom: 'Ekonom',
  Mimibazar: 'Mimibazar',
  GolfDigest: 'GolfDigest',
  Kutilove: 'Kutilove.cz',
  Marianne: 'Marianne.cz',
  Elle: 'Elle.cz',
  Chip: 'Chip.cz',
  SvetZeny: 'Světženy.cz',
  NaseKrasnaZahrada: 'NKZ.cz',
  Kinobox: 'Kinobox.cz',
  Tvguru: 'TVGuru.cz',
  Devetletnatvrdo: 'Datarun.cz',
  Soiree: 'Datarun.cz',
  Vysokenapeti: 'Datarun.cz',
  Ko: 'Datarun.cz',
  Zivotvgymu: 'Datarun.cz',
  Vsechnomacenu: 'Datarun.cz',
  Padouchnebohrdina: 'Datarun.cz',
  FajntipLifestyle: 'Fajntip',
  FreeLite: 'VímeVíc.cz',
  Netflixer: 'Netflixer.cz',
  GrapesMag: 'GrapesMag',
  ZivotCestovatele: 'Život Cestovatele',
  ZenaIn: 'Žena-IN',
  Extra: 'eXtra.cz',
  Lifee: 'Lifee.cz',
  Proboha: 'Proboha!',
  HlidaciPes: 'Hlídací Pes',
  Arecenze: 'Arecenze',
  Fajnstyl: 'Fajnstyl',
  I60: 'I60',
  Flowee: 'Flowee',
  MeziZenami: 'MeziŽenami.cz',
  WeboZdravi: 'WeboZdraví.cz',
  Borovan: 'Borovan.cz',
  AutoZive: 'AutoŽivě.cz',
  Udalosti247: 'Události247.cz',
  SportyZive: 'SportyŽivě',
  ArmadniZpravodaj: 'ArmádníZpravodaj.cz'
}

export const SOURCE_IMAGES = {
  Nespechej: 'nespechej.png',
  NasDum: 'nasdum.png',
  PlneZdravi: 'plnezdravi.png',
  NaseZahrada: 'nasezahrada.png',
  EPenize: 'epenize.png',
  SvetCestovatele: 'svetcestovatele.png',
  Aktualne: 'aktualne.svg',
  Vareni: 'vareni.svg',
  Woman: 'zena.svg',
  Nova: 'tn.svg',
  Utulne: 'utulne.svg',
  Video: 'aktualne.svg',
  Magazin: 'aktualne.svg',
  MagazinZahrada: 'magazinzahrada.png',
  AktualneAuto: 'aktualne.svg',
  VitalWeb: 'vitalweb.svg',
  Kinotip: 'kinotip.png',
  Zdrave: 'zdrave.svg',
  FajntipPsychologie: 'fajntip.png',
  Ekonom: 'ekonom.svg',
  Mimibazar: 'mimibazar.png',
  GolfDigest: 'golfdigest.png',
  Kutilove: 'kutilove.png',
  NaseKrasnaZahrada: 'nasekrasnazahrada.png',
  SvetZeny: 'svetzeny.png',
  Marianne: 'marianne.png',
  Elle: 'elle.png',
  Kinobox: 'kinobox.png',
  Tvguru: 'tvguru.png',
  Chip: 'chip.png',
  Devetletnatvrdo: 'datarun.png',
  Soiree: 'datarun.png',
  Vysokenapeti: 'datarun.png',
  Ko: 'datarun.png',
  Zivotvgymu: 'datarun.png',
  Vsechnomacenu: 'datarun.png',
  Padouchnebohrdina: 'datarun.png',
  FajntipLifestyle: 'fajntip.png',
  FreeLite: 'freelite.png',
  Netflixer: 'netflixer.jpg',
  GrapesMag: 'grapesmag.png',
  ZivotCestovatele: 'zivot-cestovatele.png',
  ZenaIn: 'zena-in.png',
  Extra: 'extra.png',
  Lifee: 'lifee.png',
  Proboha: 'proboha.png',
  HlidaciPes: 'hlidacipes.png',
  Arecenze: 'arecenze.png',
  Fajnstyl: 'fajnstyl.png',
  I60: 'i60.png',
  Flowee: 'flowee.png',
  MeziZenami: 'mezizenami.png',
  WeboZdravi: 'webozdravi.png',
  Borovan: 'borovan.png',
  AutoZive: 'autozive.png',
  Udalosti247: 'udalosti247.png',
  SportyZive: 'sportyzive.png',
  ArmadniZpravodaj: 'armadnizpravodaj.png'
}

export const ALREADY_USED_ARTICLES_WIDGETS = [
  'dynamiclead',
  'news',
  'video',
  'woman',
  'sport',
  'magazine',
  'finance',
  'newsfeed',
  'celebritiesInverted'
]
