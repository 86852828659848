const WindowEcoProps = {
  TOKEN: 'token',
  SERVICE: 'service',
  SID: 'sid',
  READY: 'ready'
}

export const setWindowEcoSid = (sidCookie) => {
  window.eco = window.eco || {}
  window.eco[WindowEcoProps.SID] = sidCookie
  window.eco[WindowEcoProps.READY] = true
}

export const setWindowEcoCpexTuple = ({ userUuid, userService }) => {
  window.eco = window.eco || {}
  window.eco[WindowEcoProps.TOKEN] = userUuid
  window.eco[WindowEcoProps.SERVICE] = userService
  window.eco[WindowEcoProps.READY] = true
}
